import { QueryRequestHelper } from "@common/query-request-helper";
import FormBuilder from "@components/common/FormBuilder";
import DashboardLayout from "@layouts/dashboard/dashboard-layout";
import {
  createExpenseEntry,
  deleteExpenseEntryReceipts,
  getAllExpenseEntryForm,
  getAllExpenseEntryFormEdit,
  getAllExpenseEntryFormEditExpenseType,
  getExpenseFormByExpenseType,
  updateExpenseEntry,
  uploadExpenseEntryReceipts,
} from "@services/expense-entry.service";
import { getValue, setValue } from "@utils/lodash";
import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import "../expense.scss";
import DragAndDropFileComponent from "@common/FileUpload/file-drag-drop";
import { mediaUpload } from "@services/upload.service";
import SimpleReactValidator from "simple-react-validator";
import {
  FormAppendDataRequestHelper,
  FormRequestHelper,
  checkBoolean,
} from "@components/common/FormBuilder/request-helper";
import { toast } from "react-toastify";
import {
  formatText,
  removeNullOrUndefinedProperties,
  sortJSONObjectArray,
} from "@common/text-helpers";
import DocumentImageList from "@common/FileUpload/document-image-list";
import SingleSelectSearchDropdown from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdown";
import BackSvgComponent from "@assets/svg-components/back";
import DeleteModal from "@common/popup/DeleteModal";
import { autoScanDocument } from "@services/ai.service";
import { getAllExpenseTypes } from "@services/expense-type.service";
import { getAllCurrency } from "@services/currency.service";
import Loader from "@components/common/Loader";
import UploadLoader from "@components/common/UploadLoader";
import { getAllVehicles } from "@services/vehicle.service";
import InputField from "@common/InputRuleFeild";
import { getSpecificMileageAmount } from "@services/mileage.service";
import NoAccessPage from "@components/common/NoAccess";
import CreateReportPopup from "@pages/Client/Reports/create-popup";
import { createExpenseReport, getAllExpenseFormReport } from "@services/expense-reports.service";
import ReportsPopup from "@components/Reports/components/report-add-popup";
import { convertDate } from "@common/date-helpers";
import { expenseReportsSubmittedSearchableDropdown } from "@components/common/CustomSearchableDropdown/SearchAPIs";
import { getLookupAPIs } from "@components/common/CustomSearchableDropdown/lookup-apis";
import SingleSelectSearchDropdownReport from "@components/common/CustomSearchableDropdown/SingleSelectSearchDropdownReport";

function ClientExpenseCreate(props: any) {
  let navigate = useNavigate();
  let params = useParams();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const urlParams = Object.fromEntries(urlSearchParams.entries());
  let mainQueryRequest = QueryRequestHelper(urlParams);

  const simpleValidator = useRef(new SimpleReactValidator());
  const simpleValidator1 = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState(0);
  /* -------------------------------------------------------------------------- */
  /*                               UseState Section                             */
  /* -------------------------------------------------------------------------- */
  const [isLoading, setIsLoading] = React.useState(true);
  const [form, setForm] = React.useState<any[]>([]);
  const [formInfo, setFormInfo] = useState({});
  const [expense_report_id, setExpenseReportId] = useState("");
  const [expense_type, setExpenseTypeId] = useState("");

  /* -------------------------------------------------------------------------- */
  /*                               UseEffect Section                            */
  /* -------------------------------------------------------------------------- */

  useEffect(() => {
    if (Object.keys(urlParams).length === 0 && props.permissionAPITriggered) {
      getData(true);
      getFormData();
      fetchDropdownData()
    }
  }, [props.permissionAPITriggered]);
  useEffect(() => {
    if (Object.keys(urlParams).length > 0 && props.permissionAPITriggered) {
      getData(false);
      
      if (getValue(urlParams, `expense_report_id`, "")) {
        setExpenseReportId(getValue(urlParams, `expense_report_id`, ""));
      }
      if (getValue(urlParams, `expense_type_id`, "")) {
        setExpenseTypeId(getValue(urlParams, `expense_type_id`, ""));
        // getFormByExpenseType(getValue(urlParams, `expense_type_id`, ""));
      } else {
        setExpenseTypeId("");
        setForm([]);
        setFormInfo({});
      }
      getFormData();
      fetchDropdownData()
    }
  }, [window.location.href, props.permissionAPITriggered]);

  //DropDown Code
  // const dropdownRef = useRef(null);
  const [reportList,setReportList] = useState([])
  const fetchDropdownData = async () => {
    try {
      setIsLoading(true);
      const lookupAPI = "expense_reports_submitted"; 
      const lookupID = ""; 
      let resp = await getLookupAPIs(lookupAPI, lookupID, "");
      if (resp) {
        setReportList(resp);
        setIsLoading(false);
      } else {
        setReportList([]);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };



  /* -------------------------------------------------------------------------- */
  /*                               API Section                                  */
  /* -------------------------------------------------------------------------- */
  const getData = async (status: boolean) => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(status);
      let resp;
      if (getValue(params, `id`, "")) {
        resp = await getAllExpenseEntryFormEdit(getValue(params, `id`, ""));
      } else {
        resp = await getAllExpenseEntryForm(queryRequest);
      }

      if (resp) {
        setFormInfo(getValue(resp, `data`, {}));
        if (getValue(resp, `data.mileage.id`, "")) {
          getVehicleList();
        }
        if (getValue(params, `id`, "")) {
          setExpenseReportId(
            getValue(resp, `data.expense.expense_report.id`, "")
          );
          // setForm(getValue(resp, `data.form_layout`, []));
          let length = getValue(resp, `data.form_layout.length`, 0);
          let array = getValue(resp, `data.form_layout`, []);
          setDocuments(getValue(resp, `data.expense.receipts`, []));
          let expense = getValue(resp, `data.expense`, {});
          // let payload = {
          //   currency_code: getValue(resp, `data.expense.claimed_currency`, ""),
          // };
          // if (getValue(resp, `data.expense.claimed_currency`, "")) {
          //   let queryRequest = QueryRequestHelper(payload);
          //   let currency = await getAllCurrency(queryRequest);
          //   if (currency) {
          //     expense["currency"] = {
          //       id: getValue(currency, `data.data[${0}].id`, ""),
          //     };
          //   }
          // }
          for (let i = 0; i < length; i++) {
            FormAppendDataRequestHelper(array[i], expense);
          }
          let findExpenseType = array.find(
            (item: object) =>
              getValue(item, `field_name`, "") === "expense_type"
          );
          // setExpenseTypeId(getValue(findExpenseType, `field_value`, ""));
          if (getValue(urlParams, `expense_type_id`, "")) {
            getFormByExpenseType(getValue(urlParams, `expense_type_id`, ""));
          }
          if (getValue(findExpenseType, `field_value`, "")) {
            getFormByExpenseType(getValue(findExpenseType, `field_value`, ""));
          }
          setIsLoading(false);
        } else {
          // let builder = getValue(resp, `data.form_layout`, []).map(
          //   (item: any) => ({
          //     ...item,
          //     field_value: checkBoolean(getValue(item, `field_value`, "")),
          //   })
          // );
          // setForm(builder);
          getFormByExpenseType(
            getValue(urlParams, `expense_type_id`, "")
              ? getValue(urlParams, `expense_type_id`, "")
              : expense_type
          );
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleLoading, setVehicleLoading] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [distance, setDistance] = useState("");
  const getVehicleList = async () => {
    try {
      let payload = {
        page_no: 1,
        page_size: 100,
      };
      setVehicleLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await getAllVehicles(queryRequest);
      if (resp) {
        setVehicleList(
          getValue(resp, `data.vehicles`, []).map((item: object) => ({
            ...item,
            value: getValue(item, `id`, ""),
            name: getValue(item, `vehicle_name`, ""),
            label: getValue(item, `vehicle_name`, ""),
          }))
        );
        setVehicleLoading(false);
      } else {
        setVehicleLoading(false);
      }
    } catch (error) {
      setVehicleLoading(false);
    }
  };
  const [submitLoading, setSubmitLoading] = useState(false);
  const handleSubmit = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoading(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
          if (
            !getValue(params, `id`, "") &&
            !getValue(urlParams, `expense_report_id`, "")
          ) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          }
          if (!getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/expenses/detail/${getValue(resp, `data.id`, "")}`
            );
          }
          if (getValue(urlParams, `expense_report_id`, "")) {
            navigate(
              `/client/reports/detail/${getValue(
                urlParams,
                `expense_report_id`,
                ""
              )}`
            );
          }

          setSubmitLoading(false);
        } else {
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
      }
    }
  };

  const handleSubmitForm = async () => {
    if (getValue(mainInfo, `is_receipt_mandatory`, false)) {
      if (getValue(documents, `length`, 0) > 0) {
        handleSubmit();
      } else {
        toast.error("Document is mandatory");
      }
    } else {
      handleSubmit();
    }
  };

  const handleHideValidation = () => {
    simpleValidator.current.errorMessages = {};
    simpleValidator.current.fields = {};
    simpleValidator.current.hideMessages();
    forceUpdate(0);
  };
  /* -------------------------------------------------------------------------- */
  /*                               Upload Section                                */
  /* -------------------------------------------------------------------------- */
  const fileTypes = ["PDF", "JPEG", "XLSX", "CSV", "XLSM", "XLX", "JPG", "PNG"];
  const [documents, setDocuments] = useState<any>([]);
  const [documentList, setDocumentList] = useState<any>([]);
  const [length, setLength] = useState(0);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadStaticLoading, setUploadStaticLoading] = useState(false);
  const handleUploadDocuments = async (e: any) => {
    if (getValue(params, `id`, "")) {
      let lengthOfUploadedFiles = Array.from(e).length;
      setLength(lengthOfUploadedFiles);
    }
    Array.from(e).forEach(async (item: any) => {
      let formData = new FormData();
      formData.append("file", item);
      try {
        setUploadStaticLoading(true);
        let resp = await mediaUpload(formData);
        if (resp) {
          setDocuments((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          setDocumentList((prevDocuments: any) => [
            ...prevDocuments,
            getValue(resp, `data`, {}),
          ]);
          if (getValue(params, `id`, "")) {
            setUploadLoading(true);
          }
          e = null;
          setUploadStaticLoading(false);
        } else {
          e = null;
          setUploadStaticLoading(false);
        }
      } catch (error) {
        e = null;
        setUploadStaticLoading(false);
      }
    });
  };
  useEffect(() => {
    if (
      length > 0 &&
      getValue(documentList, `length`, 0) > 0 &&
      getValue(documentList, `length`, 0) === length
    ) {
      uploadFiles();
    } else {
      setLength(0);
    }
  }, [length > 0 && getValue(documentList, `length`, 0) === length]);
  const uploadFiles = async () => {
    try {
      let payload = {};
      let requestPayload: any = payload;
      requestPayload["receipt_ids"] =
        getValue(documents, `length`, 0) > 0
          ? documents.map((item: object) => getValue(item, `id`, ""))
          : [];
      let resp = await uploadExpenseEntryReceipts(
        getValue(params, `id`, ""),
        removeNullOrUndefinedProperties(requestPayload)
      );
      if (resp) {
        setUploadLoading(false);
        setLength(0);
        setDocumentList([]);
        toast.success(getValue(resp, `message`, ""));
        if (getValue(params, `id`, "")) {
          getData(false);
        }
      } else {
        setLength(0);
        setDocumentList([]);
        setUploadLoading(false);
      }
    } catch (error) {
      setLength(0);
      setDocumentList([]);
      setUploadLoading(false);
    }
  };
  /* ------------------------------- Delete Section -------------------------- */

  const [deleteValue, setDeleteValue] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const handleDeleteFun = (id: string, value: string) => {
    if (getValue(params, `id`, "")) {
      setDeleteValue(value);
      setDeleteId(id);
      handleDelete();
    } else {
      setDocuments((prevDocuments: any) =>
        prevDocuments.filter((item: any) => getValue(item, `id`, "") !== id)
      );
    }
  };
  const [isDelete, setIsDelete] = useState(false);
  const handleDelete = () => {
    setIsDelete(!isDelete);
  };
  const handleDeleteFunction = async () => {
    try {
      let resp = await deleteExpenseEntryReceipts(
        getValue(params, `id`, ""),
        deleteId
      );
      if (resp) {
        toast.success(getValue(resp, `message`, ""));
        handleDelete();
        getData(false);
      }
    } catch (error) {}
  };
  const [scanLoading, setScanLoading] = useState(false);
  const handleScanDoc = async (obj: object) => {
    try {
      let payload = {
        document_id: getValue(obj, `id`, ""),
        hash: getValue(obj, `hash`, ""),
        filePath: getValue(obj, `url`, ""),
      };
      setScanLoading(true);
      let queryRequest = QueryRequestHelper(payload);
      let resp = await autoScanDocument(queryRequest);
      if (resp) {
        //----------- Expense Type --------------//
        let payload = {
          expense_type_name: getValue(resp, `data.expense_category`, ""),
        };
        let queryRequest = QueryRequestHelper(payload);
        let expenseType = await getAllExpenseTypes(queryRequest);

        //----------- Currency --------------//
        let payloadReq = {
          currency_code: getValue(resp, `data.currency_code`, ""),
        };
        let queryRequestCurrency = QueryRequestHelper(payloadReq);
        let currency = await getAllCurrency(queryRequestCurrency);

        //----------- Finding Index --------------//
        const amountIndex = form.findIndex(
          (obj) => getValue(obj, `field_name`, "") === "amount"
        );
        const expenseindex = form.findIndex(
          (obj) => getValue(obj, `field_name`, "") === "expense_date"
        );
        const expenseTypeindex = form.findIndex(
          (obj) => getValue(obj, `field_name`, "") === "expense_type"
        );
        const currencyIndex = form.findIndex(
          (obj) => getValue(obj, `field_name`, "") === "currency_id"
        );
        setValue(
          form,
          `[${amountIndex}].field_value`,
          getValue(resp, `data.total_amount`, null)
        );
        setValue(
          form,
          `[${expenseindex}].field_value`,
          getValue(resp, `data.invoice_date`, null)
            ? getValue(resp, `data.invoice_date`, null)
            : null
        );
        setValue(
          form,
          `[${expenseTypeindex}].field_value`,
          getValue(expenseType, `data.data.[${0}].id`, "")
        );
        setValue(
          form,
          `[${currencyIndex}].field_value`,
          getValue(currency, `data.data.[${0}].id`, "")
        );
        setForm([...form]);
        setScanLoading(false);
      } else {
        setScanLoading(false);
      }
    } catch (error) {
      setScanLoading(false);
    }
  };

  const handleExpenseType = (id: string) => {
    let payload: any = {
      ...urlParams,
      expense_type_id: id,
    };
    let queryRequest = QueryRequestHelper(payload);
    navigate(`${window.location.pathname}?${queryRequest}`);
  };

  const [mainInfo, setMainInfo] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const getFormByExpenseType = async (id: string) => {
    if (id)
      try {
        let payload = {
          expense_type_id: id,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp;
        setFormLoading(true);
        if (getValue(params, `id`, "")) {
          resp = await getAllExpenseEntryFormEditExpenseType(
            getValue(params, `id`, ""),
            queryRequest
          );
        } else {
          resp = await getExpenseFormByExpenseType(queryRequest);
        }

        if (resp) {
          setMainInfo(getValue(resp, `data`, {}));
          if (getValue(params, `id`, "")) {
            setExpenseReportId(
              getValue(resp, `data.expense.expense_report.id`, "")
            );
            setExpenseTypeId(
              getValue(urlParams, `expense_type_id`, "")
                ? getValue(urlParams, `expense_type_id`, "")
                : getValue(resp, `data.expense.expense_type.id`, "")
            );

            if (
              getValue(resp, `data.mileage.mileage_expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, "")) ||
              getValue(resp, `data.expense.expense_type.id`, "") ==
                (getValue(urlParams, `expense_type_id`, "")
                  ? getValue(urlParams, `expense_type_id`, "")
                  : getValue(resp, `data.expense.expense_type.id`, ""))
            ) {
              setDistance(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                )
              );
              setVehicle(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                )
              );
              getAmountFromMileage(
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.distance`,
                  ""
                ),
                getValue(
                  resp,
                  `data.expense.expense_entry_mileage.vehicle.id`,
                  ""
                ),
                getValue(resp, `data.expense.expense_date`, "")
              );
            } else {
              setVehicle("");
              setDistance("");
            }

            // setExpenseTypeId(getValue(resp, `data.expense.expense_type.id`, ""));
            // setForm(
            //   getValue(resp, `data.form_layout`, []).filter(
            //     (item: object) =>
            //       getValue(item, `field_name`, "") !== "expense_type"
            //   )
            // );
            let length = getValue(resp, `data.form_layout.length`, 0);
            let array = sortJSONObjectArray(
              getValue(resp, `data.form_layout`, []),
              "seq_no"
            );
            setDocuments(getValue(resp, `data.expense.receipts`, []));
            let expense = getValue(resp, `data.expense`, {});
            let payload = {
              currency_code: getValue(
                resp,
                `data.expense.claimed_currency`,
                ""
              ),
            };
            if (getValue(resp, `data.expense.claimed_currency`, "")) {
              let queryRequest = QueryRequestHelper(payload);
              let currency = await getAllCurrency(queryRequest);
              if (currency) {
                expense["currency"] = {
                  id: getValue(currency, `data.data[${0}].id`, ""),
                };
              }
            }
            for (let i = 0; i < length; i++) {
              FormAppendDataRequestHelper(array[i], expense);
            }
            setForm(
              array.filter(
                (item: object) =>
                  getValue(item, `field_name`, "") !== "expense_type"
              )
            );
            setFormLoading(false);
          } else {
            let builder =
              getValue(resp, `data.form_layout.length`, 0) > 0 &&
              sortJSONObjectArray(
                getValue(resp, `data.form_layout`, []),
                "seq_no"
              ).map((item: any) => ({
                ...item,
                field_value: checkBoolean(getValue(item, `field_value`, "")),
              }));
            setForm(
              builder.filter(
                (item: object) =>
                  getValue(item, `field_name`, "") !== "expense_type"
              )
            );
            setFormLoading(false);
          }
        }
      } catch (error) {
        setFormLoading(false);
      }
  };

  let date: any =
    getValue(form, "length", 0) > 0
      ? form.find(
          (item: object) => getValue(item, "field_name", "") === "expense_date"
        )
      : {};
  const [triggerAPI, setTriggerAPI] = useState(false);
  useEffect(() => {
    let date: any =
      getValue(form, "length", 0) > 0
        ? form.find(
            (item: object) =>
              getValue(item, "field_name", "") === "expense_date"
          )
        : {};
    if (
      distance &&
      vehicle &&
      getValue(date, "field_value", "") &&
      !triggerAPI
    ) {
      setTriggerAPI(true);
      getAmountFromMileage(
        distance,
        vehicle,
        getValue(date, "field_value", "")
      );
    }
  }, [distance, vehicle, date, !triggerAPI]);

  const getAmountFromMileage = async (
    distance: any,
    vehicle: string,
    date: any
  ) => {
    if (distance && vehicle && date)
      try {
        let payload = {
          expense_date: date,
          distance: distance,
          vehicle: vehicle,
        };
        let queryRequest = QueryRequestHelper(payload);
        let resp = await getSpecificMileageAmount(queryRequest);
        if (resp) {
          let data: any = form.map((item: object) => {
            if (getValue(item, "field_name", "") === "amount") {
              return {
                ...item,
                field_value: getValue(resp, "data.amount", ""),
                disabled: true,
              };
            }
            if (getValue(item, "field_name", "") === "currency_id") {
              return {
                ...item,
                field_value: getValue(resp, "data.base_currency.id", ""),
                disabled: true,
              };
            }
            return item;
          });
          setForm([...data]); // Create a new array using spread operator
        } else {
        }
      } catch (error) {
        let data: any = form.map((item: object) => {
          if (getValue(item, "field_name", "") === "amount") {
            return {
              ...item,
              field_value: "",
              disabled: false,
            };
          }
          if (getValue(item, "field_name", "") === "currency_id") {
            return {
              ...item,
              field_value: null,
              disabled: false,
            };
          }
          return item;
        });
        setForm([...data]);
      }
  };

  const expenseTypeSearchableDropdown = async (
    id: string,
    search_text: string
  ) => {
    let paylod = {
      search_text: search_text,
      page_no: 1,
      page_size: 50,
      enable_policy: true,
      id: search_text ? "" : id,
      status: "active",
    };
    let queryRequest = QueryRequestHelper(paylod);
    try {
      let resp = await getAllExpenseTypes(queryRequest);
      if (resp) {
        let data =
          getValue(resp, `data.data.length`, 0) > 0
            ? getValue(resp, `data.data`, []).map((item: object) => ({
                ...item,
                value: formatText(getValue(item, `expense_type_name`, "")),
                label: formatText(getValue(item, `expense_type_name`, "")),
                workflow_name: getValue(item, `expense_type_name`, ""),
              }))
            : [];
        return data;
      }
    } catch (error) {}
  };
  const handleChange = (e: any) => {
    const userInput = e.target.value;
    const regex = /^[0-9.]*$/; // Regular expression to allow only digits and a single decimal point
    let input = userInput.slice(0, 6);

    if (regex.test(input)) {
      return input;
    } else {
      return input ? input.replace(/[^0-9.]/g, "") : "";
    }
  };

/* -------------------------------------------------------------------------- */
  /*                                 Create section                             */
  /* -------------------------------------------------------------------------- */
  const getFormData = async () => {
    let payload: any = {};
    let queryRequest = QueryRequestHelper(payload);
    try {
      setIsLoading(true);
      let resp = await getAllExpenseFormReport(queryRequest);
      if (resp) {
        let form = getValue(resp, `data.form_layout`, []).map((item: any) => {
          if (getValue(item, `field_name`, "") === "duration_from") {
            const now = new Date();
            const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            item["field_value"] = convertDate(firstDayOfMonth);        
          } else if (getValue(item, `field_name`, "") === "duration_to") {
            item["field_value"] = convertDate(new Date());
          }
          return item; // Always return the item after making modifications
        });

        setReportFormInfo(getValue(resp, `data`, {}));
        setReportForm(form);
        // setReportForm(getValue(resp, `data.form_layout`, []));
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [reportForm, setReportForm] = React.useState<[]>([]);
  const [reportFormInfo, setReportFormInfo] = useState({});
  const [submitLoadingReport, setSubmitLoadingReport] = useState(false);
  

  // const handleSubmitCreateReport = async () => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoadingReport(true);
  //       let fields = JSON.parse(JSON.stringify(reportForm));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       let resp = await createExpenseReport(
  //         removeNullOrUndefinedProperties(requestPayload)
  //       );

  //       if (resp) {
  //         toast.success(getValue(resp, `message`, ""));
  //         handleOpenPopup();
  //         setReportForm([]);
  //         setReportFormInfo({});
  //         getFormData();
  //         if (getValue(resp, `data.id`, "")) {
  //           navigate(
  //             `/client/reports/detail/${getValue(
  //               resp,
  //               `data.id`,
  //               ""
  //             )}?${mainQueryRequest}`
  //           );
  //         } else {
  //           navigate(`/client/reports?${mainQueryRequest}`);
  //         }
  //         simpleValidator.current.hideMessages();
  //         forceUpdate(0);
  //         setSubmitLoadingReport(false);
  //       } else {
  //         setSubmitLoadingReport(false);
  //       }
  //     } catch (error) {
  //       setSubmitLoadingReport(false);
  //     }
  //   }
  // };

  const handleSubmitSaveReview = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);

        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        let resp;
        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }
        
        if (resp) {
          toast.success(getValue(resp, `message`, ""));
            navigate(
              `/client/reports/detail/${expense_report_id}`
            );
          setSubmitLoadingReport(false);
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }  
  }

  // const handleSubmitSaveReport = async () => {
  //   const formValid = simpleValidator.current.allValid();
  //   if (!formValid) {
  //     simpleValidator.current.showMessages();
  //     forceUpdate(1);
  //   } else {
  //     try {
  //       setSubmitLoadingReport(true);
  //       let fields = JSON.parse(JSON.stringify(form));
  //       let payload = FormRequestHelper(fields);
  //       let requestPayload: any = payload;
  //       requestPayload["expense_report_id"] = expense_report_id;
  //       requestPayload["expense_type"] = parseInt(expense_type);
  //       if (vehicle) {
  //         requestPayload["mileage"] = {
  //           vehicle: vehicle,
  //           distance: parseFloat(distance),
  //           mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
  //         };
  //       }
  //       // requestPayload["vehicle"] = vehicle;
  //       // requestPayload["distance"] = parseFloat(distance);
        
  //       requestPayload["amount"] = requestPayload["personal"]
  //         ? parseFloat(`-${requestPayload["amount"]}`)
  //         : requestPayload["amount"];
  //       requestPayload["personal"] =
  //         requestPayload["personal"] === "false" ? true : false;
  //       requestPayload["receipt_ids"] =
  //         getValue(documents, `length`, 0) > 0
  //           ? documents.map((item: object) => getValue(item, `id`, ""))
  //           : [];
  //       let resp;
  //       if (getValue(params, `id`, "")) {
  //         resp = await updateExpenseEntry(
  //           getValue(params, `id`, ""),
  //           removeNullOrUndefinedProperties(requestPayload)
  //         );
  //       } else {
  //         resp = await createExpenseEntry(
  //           removeNullOrUndefinedProperties(requestPayload)
  //         );
  //       }
  //       if (resp) {
  //         handleSubmitCreateReport();
  //         toast.success(getValue(resp, `message`, ""));
  //         setSubmitLoadingReport(false);
  //       } else {
  //         setSubmitLoadingReport(false);
  //       }
  //     } catch (error) {
  //       setSubmitLoadingReport(false);
  //     }
  //   }
  // }

  const handleExpenseSave = async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(form));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        requestPayload["expense_report_id"] = expense_report_id;
        requestPayload["expense_type"] = parseInt(expense_type);
        if (vehicle) {
          requestPayload["mileage"] = {
            vehicle: vehicle,
            distance: parseFloat(distance),
            mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
          };
        }
        // requestPayload["vehicle"] = vehicle;
        // requestPayload["distance"] = parseFloat(distance);
        
        requestPayload["amount"] = requestPayload["personal"]
          ? parseFloat(`-${requestPayload["amount"]}`)
          : requestPayload["amount"];
        requestPayload["personal"] =
          requestPayload["personal"] === "false" ? true : false;
        requestPayload["receipt_ids"] =
          getValue(documents, `length`, 0) > 0
            ? documents.map((item: object) => getValue(item, `id`, ""))
            : [];
        let resp;
        
        if (getValue(params, `id`, "")) {
          resp = await updateExpenseEntry(
            getValue(params, `id`, ""),
            removeNullOrUndefinedProperties(requestPayload)
          );
        } else {
          resp = await createExpenseEntry(
            removeNullOrUndefinedProperties(requestPayload)
          );
        }

        if (resp) {
          toast.success(getValue(resp, `message`, ""));
            // navigate(
            //   `/client/reports/detail/${expense_report_id}`
            // );
          setSubmitLoadingReport(false);
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }  
  }

  const handleSubmitSaveReport =async () => {
    const formValid = simpleValidator.current.allValid();
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    } else {
      try {
        setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(reportForm));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await createExpenseReport(
          removeNullOrUndefinedProperties(requestPayload)
        );

        if (resp) {
          // toast.success(getValue(resp, `message`, ""));
          setReportForm([]);
          setReportFormInfo({});
          getFormData();
          setExpenseReportId(
            getValue(resp, `data.id`, "")
          );
          // handleExpenseSave();
          
          /* ---------------- */
          setSubmitLoadingReport(true);
          let fields = JSON.parse(JSON.stringify(form));
          let payload = FormRequestHelper(fields);
          let requestPayload: any = payload;
          requestPayload["expense_report_id"] = getValue(resp, `data.id`, "");
          requestPayload["expense_type"] = parseInt(expense_type);
          if (vehicle) {
            requestPayload["mileage"] = {
              vehicle: vehicle,
              distance: parseFloat(distance),
              mileage_unit: getValue(formInfo, `mileage.mileage_unit`, ""),
            };
          }
          // requestPayload["vehicle"] = vehicle;
          // requestPayload["distance"] = parseFloat(distance);
          
          requestPayload["amount"] = requestPayload["personal"]
            ? parseFloat(`-${requestPayload["amount"]}`)
            : requestPayload["amount"];
          requestPayload["personal"] =
            requestPayload["personal"] === "false" ? true : false;
          requestPayload["receipt_ids"] =
            getValue(documents, `length`, 0) > 0
              ? documents.map((item: object) => getValue(item, `id`, ""))
              : [];
          let respForm;
          
          if (getValue(params, `id`, "")) {
            respForm = await updateExpenseEntry(
              getValue(params, `id`, ""),
              removeNullOrUndefinedProperties(requestPayload)
            );
          } else {
            respForm = await createExpenseEntry(
              removeNullOrUndefinedProperties(requestPayload)
            );
          }
          
          if (respForm) {
  
            toast.success(getValue(resp, `message`, ""));
              navigate(
                `/client/reports/detail/${getValue(resp, `data.id`, "")}`
              );
            setSubmitLoadingReport(false);
          } else {
            setSubmitLoadingReport(false);
          }
          /* ---------------- */

          simpleValidator.current.hideMessages();
          forceUpdate(0);
          setSubmitLoadingReport(false);
          
// if (getValue(resp, `data.id`, "")) {
          //   navigate(
          //     `/client/reports/detail/${getValue(
          //       resp,
          //       `data.id`,
          //       ""
          //     )}?${mainQueryRequest}`
          //   );
          // } else {
          //   navigate(`/client/reports?${mainQueryRequest}`);
          // }
        } else {
          setSubmitLoadingReport(false);
        }
      } catch (error) {
        setSubmitLoadingReport(false);
      }
    }
  }

  const [isOpen, setISOpen] = useState(false);
  const isToggle = () => setISOpen(!isOpen);

  const handleSaveSubmit = async () => {
    // isToggle();
    if (getValue(mainInfo, `is_receipt_mandatory`, false)) {
      if (getValue(documents, `length`, 0) > 0) {
        if(expense_report_id) {
          handleSubmitSaveReview();
        } else {
          handleOpenPopup();
        }

      } else {
        toast.error("Document is mandatory");
      }
    } else {
      if(expense_report_id) {
        handleSubmitSaveReview();
      } else {
        handleOpenPopup();
      }
  }
  }

  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const handleOpenPopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  const [createReportClicked, setCreateReportClicked] = useState(false);
  const onClickAdd = async () => {
    setCreateReportClicked(true);
    handleOpenPopup();
  }


  const createReportNewFromDropdown = async () => {
    try {
      setSubmitLoadingReport(true);
        let fields = JSON.parse(JSON.stringify(reportForm));
        let payload = FormRequestHelper(fields);
        let requestPayload: any = payload;
        let resp = await createExpenseReport(
          removeNullOrUndefinedProperties(requestPayload)
        );
        if(resp) {
          setSubmitLoadingReport(false);
          handleOpenPopup();
          setReportForm([]);
          setReportFormInfo({});
          fetchDropdownData();
          setExpenseReportId(getValue(resp, `data.id`, ""));
        }
    } catch (error) {
      
    }
  }

  return (
    <DashboardLayout
      permissions={getValue(props, `tabPermissionList`, [])}
      subTabPermissionList={getValue(props, `subTabPermissionList`, [])}
    >
      {!isLoading &&
      !getValue(props, `subTabPermissionList`, []).includes("expenses") ? (
        <NoAccessPage />
      ) : (
        <>
          <div className="expense-create-wrapper">
            <div className="expense-create-wrapper__header d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <NavLink
                  to={
                    getValue(urlParams, `expense_report_id`, "")
                      ? `/client/reports/detail/${getValue(
                          urlParams,
                          `expense_report_id`,
                          ""
                        )}`
                      : getValue(params, `id`, "")
                      ? `/client/expenses/detail/${getValue(params, `id`, "")}`
                      : "/client/expenses"
                  }
                  // className="expense-create-wrapper__header-back-link"
                >
                  <BackSvgComponent />
                </NavLink>
                <a href="#0" className="header_text ">
                  {getValue(params, `id`, "") ? "Update" : "New"} Expense
                </a>
              </div>
              <div className="expense-create-wrapper__cta-btns">
                <NavLink
                  to={
                    getValue(urlParams, `expense_report_id`, "")
                      ? `/client/reports/detail/${getValue(
                          urlParams,
                          `expense_report_id`,
                          ""
                        )}`
                      : getValue(params, `id`, "")
                      ? `/client/expenses/detail/${getValue(params, `id`, "")}`
                      : "/client/expenses"
                  }
                >
                  <button className="ascent-button ascent-button--header-buttons ascent-button--primary">
                    Cancel
                  </button>
                </NavLink>

                {!expense_report_id ? (
                  <>
                    {getValue(props, `permissions`, []).includes("update") &&
                      getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit Later"}
                        </button>
                      )}
                    {getValue(props, `permissions`, []).includes("create") &&
                      !getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit Later"}
                        </button>
                      )}
                  </>
                ) : null}

                {expense_report_id ? (
                  <>
                    {!getValue(params, `id`, "") && (
                      <button
                        className="ascent-button ascent-button--header-buttons ascent-button--secondary"
                        onClick={handleSaveSubmit}
                        disabled={submitLoadingReport}
                      >
                        {submitLoadingReport
                          ? "Please wait..."
                          : expense_report_id
                          ? "Save & View Report"
                          : " Save & Submit"}
                      </button>
                    )}

                    {getValue(props, `permissions`, []).includes("update") &&
                      getValue(params, `id`, "") && (
                        <button
                          className="ascent-button ascent-button--header-buttons ascent-button--secondary me-3"
                          onClick={handleSubmitForm}
                          disabled={submitLoading}
                        >
                          {submitLoading
                            ? "Please wait..."
                            : getValue(params, `id`, "")
                            ? "Update"
                            : "Save & Submit"}
                        </button>
                      )}
                  </>
                ) : null}
              </div>
            </div>
            {scanLoading ? (
              <Loader />
            ) : (
              <div className="expense-create-wrapper_container">
                <div className="expense-create-wrapper_left">
                  <div className="expense-create-wrapper_left_container">
                    <h6 className="expense-create-wrapper_left_container_header">
                      Attach Report from Computer or Cloud{" "}
                      {getValue(mainInfo, `is_receipt_mandatory`, false) && (
                        <span className="form-label-error">*</span>
                      )}
                    </h6>
                    <DragAndDropFileComponent
                      uploadFile={handleUploadDocuments}
                      name="file"
                      types={fileTypes}
                      multiple
                      classes="bulk-upload_drag-n-drop-wrapper position-absolute"
                      fileName={getValue(props, `importedFile.name`, "")}
                      setImportTedFile={props.setImportTedFile}
                    />
                  </div>
                  <div className="expense-detail-wrapper_report_documentsection w-100">
                    {uploadLoading || uploadStaticLoading ? (
                      <UploadLoader />
                    ) : getValue(documents, `length`, 0) === 0 ? (
                      <p className="mt-5 mb-5 text-center">No document found</p>
                    ) : (
                      <div
                        className="d-flex  mt-3 m-4"
                        style={{ flexWrap: "wrap" }}
                      >
                        <DocumentImageList
                          documents={documents}
                          containerStayle={{ height: "100px", width: "150px" }}
                          textContainerStayle={{
                            height: "40px",
                            width: "150px",
                          }}
                          textStyle={{ fontSize: "12px" }}
                          handleRemoveDocuments={handleDeleteFun}
                          uploadLoading={uploadLoading}
                          ai
                          handleScanDoc={handleScanDoc}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="expense-create-wrapper_right">
                  <div className={`form_wrapper-pipeline`}>
                    <div className="form_builder_field">
                      <SingleSelectSearchDropdownReport
                        data={reportList}
                        label={"label"}
                        name="Reports"
                        value={expense_report_id}
                        onChange={(e: any) =>
                          setExpenseReportId(getValue(e, `id`, ""))
                        }
                        placeholder={"Choose Reports"}
                        width={"100%"}
                        selectKey={"id"}
                        showReportText
                        // lookup_api="expense_reports_submitted"
                        showLabel
                        // disabled={getValue(params, `id`, "")}
                        add
                        onClickAdd={onClickAdd}
                      />
                    </div>
                  </div>
                  <div className="border-bottom mb-16"></div>
                  <div className={`form_wrapper-pipeline`}>
                    <div className="form_builder_field">
                      <SingleSelectSearchDropdown
                        label={"label"}
                        name="Expense Type"
                        value={expense_type}
                        onChange={(e: any) => {
                          // getFormByExpenseType(getValue(e, `id`, ""));
                          setForm([]);
                          setVehicle("");
                          setDistance("");
                          setExpenseTypeId(getValue(e, `id`, ""));
                          handleHideValidation();
                          handleExpenseType(getValue(e, `id`, ""));
                        }}
                        placeholder={"Choose Expense Type"}
                        width={"100%"}
                        selectKey={"id"}
                        lookup_api="expense_type"
                        showLabel
                        mandatory
                      />
                    </div>
                    {/* <hr className="mt-4" /> */}
                  </div>

                  {formLoading ? (
                    <Loader />
                  ) : (
                    <>
                      {getValue(form, `length`, 0) === 0 ? (
                        <>
                          <div className="expense-load-form-wrapper">
                            <div className="expense-load-form-overlay text-center"></div>
                            <div className="expense-load-form-content-holder">
                              Start by Entering Expense Type
                            </div>

                            <div className="expense-load-dummy-form">
                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Expense Date</label>
                                <input type="text" className="form-control" />
                              </div>

                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Departure</label>
                                <input type="text" className="form-control" />
                              </div>

                              <div className="dummy-label-textbox-wrap">
                                <label htmlFor="">Arrival</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          {expense_type &&
                            expense_type ==
                              getValue(
                                formInfo,
                                `mileage.mileage_expense_type.id`,
                                ""
                              ) && (
                              <>
                                <div className={`form_wrapper-pipeline`}>
                                  <div className="form_builder_field">
                                    <SingleSelectSearchDropdown
                                      label={"label"}
                                      name="Vehicle"
                                      value={vehicle}
                                      onChange={(e: any) => {
                                        setTriggerAPI(false);
                                        setVehicle(getValue(e, `value`, ""));
                                      }}
                                      data={vehicleList}
                                      placeholder={"Choose Vehicle"}
                                      width={"100%"}
                                      selectKey={"id"}
                                      showLabel
                                      mandatory
                                      validator={simpleValidator}
                                    />
                                  </div>
                                </div>
                                <div className={`form_wrapper-pipeline `}>
                                  <div className="form_builder_field w-auto">
                                    <label className="form-label-error">
                                      Distance *
                                    </label>
                                    <div className="position-relative">
                                      <InputField
                                        inputType="DECIMAL"
                                        name="distance"
                                        value={distance}
                                        onChange={(e: any) => {
                                          setTriggerAPI(false);
                                          setDistance(handleChange(e));
                                        }}
                                        options={vehicleList}
                                        placeholder={"Enter distance"}
                                        width={"100%"}
                                        label="Distance"
                                        validator={simpleValidator}
                                      />
                                      <div className="position-absolute mileage_absolute">
                                        <h6>
                                          {getValue(
                                            formInfo,
                                            `mileage.mileage_unit`,
                                            ""
                                          )}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          <FormBuilder
                            fields={form}
                            setFields={setForm}
                            formOptions={formInfo}
                            simpleValidator={simpleValidator}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          <DeleteModal
            isOpen={isDelete}
            handleModal={handleDelete}
            handleSubmit={handleDeleteFunction}
            deleteValue={deleteValue}
          />

          {/* <CreateReportPopup
            isOpen={isOpen}
            isToggle={isToggle}
            form={reportForm}
            setForm={setReportForm}
            formInfo={reportFormInfo}
            simpleValidator={simpleValidator}
          /> */}

          <ReportsPopup
            showUpdatePopup={showUpdatePopup}
            setShowUpdatePopup={setShowUpdatePopup}
            form={reportForm}
            setForm={setReportForm}
            formInfo={reportFormInfo}
            simpleValidator={simpleValidator1}
            // handleSubmit={handleSubmitSaveReport}
            handleSubmit={createReportClicked ? createReportNewFromDropdown : handleSubmitSaveReport}
            submitLoading={submitLoadingReport}
          />
        </>
      )}
    </DashboardLayout>
  );
}

export default ClientExpenseCreate;
